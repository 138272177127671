var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FilterSelection",
    {
      attrs: {
        disabled: _vm.disabled,
        "get-item-string-compare": _vm.getAgentNameById
      },
      scopedSlots: _vm._u([
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [_c("AgentCell", { attrs: { id: item, variant: "row" } })]
          }
        }
      ]),
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "FormDropdown",
        {
          attrs: {
            disabled: _vm.disabled,
            "text-no-selection": "Any agent",
            "text-single-selection": "1 agent selected",
            "text-multiple-selection":
              _vm.internalValue.length + " agents selected"
          },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        _vm._l(_vm.agents, function(agent) {
          return _c(
            "DropdownItem",
            {
              key: agent.id,
              attrs: {
                disabled: _vm.disabled,
                selected: _vm.selectedById[agent.id]
              },
              nativeOn: {
                click: function($event) {
                  return _vm.toggle(agent.id)
                }
              }
            },
            [
              _c("AgentCell", {
                attrs: { id: agent.id, size: "sm", variant: "row" }
              })
            ],
            1
          )
        }),
        1
      ),
      _c("FilterOption", {
        attrs: { value: _vm.option },
        on: { change: _vm.onOptionChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }