var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FilterSelection",
    {
      attrs: {
        disabled: _vm.disabled,
        "get-item-string-compare": _vm.getMapNameById
      },
      scopedSlots: _vm._u([
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [_c("MapCell", { attrs: { id: item, variant: "row" } })]
          }
        }
      ]),
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "FormDropdown",
        {
          attrs: {
            disabled: _vm.disabled,
            "text-no-selection": "Any map",
            "text-single-selection": "1 map selected",
            "text-multiple-selection":
              _vm.internalValue.length + " maps selected"
          },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        _vm._l(_vm.maps, function(map) {
          return _c(
            "DropdownItem",
            {
              key: map.id,
              attrs: {
                disabled: _vm.disabled,
                selected: _vm.selectedById[map.id]
              },
              nativeOn: {
                click: function($event) {
                  return _vm.toggle(map.id)
                }
              }
            },
            [_c("MapCell", { attrs: { id: map.id, variant: "row" } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }